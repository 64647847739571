import { useInitMixpanel } from "@/customHooks/useMixpanel/index.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { setupAxiosBanInterceptors } from "@/services/axios/setupAxiosBanInterceptor.js";
import { ConfirmModalProvider, ToastProvider, TranslationsProvider } from "@waapi/waap-ui";
import axios from "axios";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useInitIntercom } from "../customHooks/useIntercom.js";
import { AuthorizedApp } from "./AuthorizedApp.js";
import { FakeIntercom } from "./components/FakeIntercom/index.js";
import { PrivateRoute } from "./components/PrivateRoute/index.js";
import { PublicRoute } from "./components/PublicRoute/index.js";
import { ForgotPasswordRoutes } from "./pages/ForgotPasswordRoutes/index.js";
import { ImpersonateRoutes } from "./pages/ImpersonateRoutes/index.js";
import { LoginRoutes } from "./pages/LoginRoutes/index.js";
import { LogoutRoutes } from "./pages/LogoutRoutes/index.js";
import { SignupRoutes } from "./pages/SignupRoutes/index.js";
import { EnvWarningProvider } from "./providers/env-warning/EnvWarningProvider.js";

export const AuthenticationLoader = (): JSX.Element => {
    const { t, translations, currentLanguage } = useTranslation();
    const navigate = useNavigate();
    dayjs.locale(currentLanguage);
    const { isBooted: isIntercomBooted } = useInitIntercom();
    useInitMixpanel();
    setupAxiosBanInterceptors(axios, navigate);

    return (
        <ConfirmModalProvider>
            <TranslationsProvider
                translations={{
                    toast: {
                        closeAllText: t(translations.toast.closeAll()),
                        warning: t(translations.toast.warning()),
                        info: t(translations.toast.info()),
                        success: t(translations.toast.success()),
                        error: t(translations.toast.error()),
                    },
                    select: {
                        noOptions: t(translations.select_no_options()),
                        placeholder: t(translations.select_placeholder()),
                    },
                }}
            >
                <ToastProvider>
                    <Helmet titleTemplate="%s - Ambassadors by Waalaxy" defaultTitle="Ambassadors by Waalaxy">
                        <meta name="description" content="Ambassadors by Waalaxy" />
                    </Helmet>
                    <EnvWarningProvider>
                        <Routes>
                            <Route path="/">
                                <Route
                                    index
                                    element={
                                        <PrivateRoute>
                                            <AuthorizedApp />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="signup/*"
                                    element={
                                        <PublicRoute>
                                            <SignupRoutes />
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="login/*"
                                    element={
                                        <PublicRoute>
                                            <LoginRoutes />
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="forgot-password/*"
                                    element={
                                        <PublicRoute>
                                            <ForgotPasswordRoutes />
                                        </PublicRoute>
                                    }
                                />
                                <Route path="impersonate/*" element={<ImpersonateRoutes />} />
                                <Route
                                    path="logout/*"
                                    element={
                                        <PrivateRoute>
                                            <LogoutRoutes />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="*"
                                    element={
                                        <PrivateRoute>
                                            <AuthorizedApp />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                        </Routes>
                        {!isIntercomBooted && <FakeIntercom />}
                    </EnvWarningProvider>
                </ToastProvider>
            </TranslationsProvider>
        </ConfirmModalProvider>
    );
};
