import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useAccountManager } from "@/entities/Account/hooks/useAccountManager/index.js";
import { Button, useBreakpoint } from "@waapi/waap-ui";
import { useState } from "react";
import { OnboardingPage } from "../OnboardingPage/index.js";
import { MAX_WIDTH_MAP } from "./constants.js";

export const CompleteOnboarding = (): JSX.Element => {
    const { t, translations } = useTranslation();
    const { getOnboardingUrl } = useAccountManager();
    const breakpoint = useBreakpoint();

    const [loading, setLoading] = useState(false);

    const onShowOnboarding = async () => {
        setLoading(true);

        const url = await getOnboardingUrl();
        if (!url) {
            setLoading(false);
            return;
        }

        window.open(url);
        setLoading(false);
    };

    const buttonComponent = (
        <div>
            <Button variant="tinted" loading={loading} onClick={onShowOnboarding}>
                {t(translations.complete_onboarding.button())}
            </Button>
        </div>
    );

    return (
        <OnboardingPage
            title={t(translations.complete_onboarding.title())}
            subtitles={[t(translations.complete_onboarding.subtitle())]}
            body={buttonComponent}
            maxWidth={MAX_WIDTH_MAP[breakpoint]}
        />
    );
};
