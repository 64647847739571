import { ClientGeneratorProps } from "@waapi/front-utils";
import { getAxiosClient } from "@waapi/palpatine-client";
import { palpatineLifelineMethodsMap, palpatineMethodsMap } from "./palpatineMethodsMap.js";
import { PalpatineClient } from "./types.js";

export const createPalpatineClient = ({ apiUrl, apiToken }: ClientGeneratorProps): PalpatineClient => {
    return {
        ...palpatineMethodsMap(getAxiosClient({ baseUrl: `${apiUrl}`, headers: { ...(apiToken ? { Authorization: `Bearer ${apiToken}` } : {}) } })),
        ...palpatineLifelineMethodsMap(getAxiosClient({ baseUrl: `${apiUrl}`, headers: {} })),
    };
};
