import { useSelector } from "react-redux";
import { isStripeAccount as isStripeAccountSelector } from "../../../entities/User/hooks/useUserState/selectors.js";

type IsStripeAccountProps = { children: JSX.Element };

export const IsStripeAccount = ({ children }: IsStripeAccountProps): JSX.Element => {
    const isStripeAccount = useSelector(isStripeAccountSelector);

    if (isStripeAccount) return children;

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};
