/* eslint-disable react/no-array-index-key */
import { MyAvatar } from "app/components/MyAvatar/index.js";
import { getHasRequirements } from "entities/User/hooks/useUserState/selectors.js";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import title from "../../../assets/Waalaxy_title.svg";
import logo from "../../../assets/waalien_official.svg";
import { useIntercom } from "../../../customHooks/useIntercom.js";
import { useTranslation } from "../../../customHooks/useTranslation/index.js";
import { CustomizableButton } from "../../components/CustomizableButton/index.js";
import { AskWaalaxyAccount } from "../AskWaalaxyAccount/index.js";
import { LanguageSelect } from "../LanguageSelect/index.js";
import { SettingsSecondaryMenu } from "./SettingsSecondaryMenu/index.js";
import { StyledSelectLanguageDiv, StyledSidebar, Title } from "./style.js";
import { Route, RouteKey, SubRoute } from "./types.js";

const getRouteIdWithLanguage = (id: string, language: string) => `${id}_${language}`;
const removeIconFromRoutes = (routesMap: Record<RouteKey, Route>) => {
    return Object.keys(routesMap).map((routeMap) => ({
        ...routesMap[routeMap],
        icon: null,
    }));
};
const getRouteDefinition = (
    navigate: ReturnType<typeof useNavigate>,
    location: ReturnType<typeof useLocation>,
    isOpen: boolean | undefined,
    currentRouteData: Route | SubRoute | undefined,
    { id, label, icon, path, subRoutes: subMenu, external, badge, disabled, fn }: Route,
    currentLanguage: string,
) => ({
    id: getRouteIdWithLanguage(id, currentLanguage),
    label,
    icon,
    badge,
    disabled,
    onClick: () => {
        if (fn && !(disabled && disabled.disabled)) {
            fn();
        } else if (external && !(disabled && disabled.disabled)) {
            window.open(path, "_blank");
        } else if (location.pathname !== path && !(disabled && disabled.disabled)) navigate(path);
    },
    subMenu: subMenu
        ? {
              entries: subMenu?.map(({ label: subLabel, path: subPath, id: subId }) => ({
                  id: getRouteIdWithLanguage(subId, currentLanguage),
                  label: subLabel,
                  onClick: () => {
                      if (disabled?.disabled === true) return;

                      if (location.pathname !== subPath) navigate(subPath);
                  },
              })),
              isOpen: isOpen === false ? false : !!(currentRouteData?.id === id || subMenu.some((entry) => entry.id === currentRouteData?.id)),
          }
        : undefined,
});

const findRoute = (params: { routeKey: string; routes: Array<Route>; subRoutes: Array<SubRoute> }) => {
    const { routeKey, routes, subRoutes } = params;
    const foundRoute = routes.find(({ id }) => {
        return id === routeKey;
    })?.id;
    if (foundRoute) return foundRoute;
    const foundSubRoute = subRoutes.find(({ id }) => {
        return id === routeKey;
    })?.id;
    return foundSubRoute ? routeKey : "home";
};

const secondaryMenuMap: Partial<
    Record<
        RouteKey,
        {
            element: JSX.Element;
            menuWidth?: string;
        }
    >
> = {
    settings: { element: <SettingsSecondaryMenu />, menuWidth: "auto" },
};

export function SideBar(): JSX.Element {
    const { t, translations, currentLanguage } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { openIntercom } = useIntercom();
    const { pathname } = useLocation();

    const hasRequirements = useSelector(getHasRequirements);

    const routesMap: Record<RouteKey, Route> = useMemo(
        () => ({
            home: {
                path: "/home",
                label: t(translations.sidebar.route.dashboard()),
                id: "home",
                icon: "home",
            },
            affiliates: {
                path: "/affiliates",
                label: t(translations.sidebar.route.affiliates()),
                id: "affiliates",
                icon: "linkedinGroup",
            },
            links: {
                path: "/links",
                label: t(translations.sidebar.route.links()),
                id: "links",
                icon: "connexion",
            },
            settings: {
                path: "/settings",
                label: t(translations.sidebar.route.settings()),
                id: "settings",
                icon: <MyAvatar customSize={{ height: 20, width: 20 }} />,
                sidebarStyle: "floating",
                badge: hasRequirements
                    ? {
                          color: "error",
                          content: t(translations.sidebar.badge.missing_settings_infos()),
                      }
                    : undefined,
            },
            faq: {
                path: "/faq",
                label: t(translations.sidebar.route.faq()),
                id: "faq",
                icon: "questionMark",
            },
            contact: {
                path: "/contact",
                label: t(translations.sidebar.route.contact()),
                id: "contact",
                icon: "contact",
                fn: () => openIntercom(),
            },
            demo: {
                path: t(translations.sidebar.route.demoLink()),
                label: t(translations.sidebar.route.demo()),
                id: "demo",
                icon: "play",
                external: true,
            },
        }),
        [currentLanguage, hasRequirements],
    );

    const [visible, setVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>(getRouteIdWithLanguage("home", currentLanguage));

    const routes = useMemo<Route[]>(() => Object.values(routesMap), [JSON.stringify(removeIconFromRoutes(routesMap))]);
    const subRoutes = useMemo<SubRoute[]>(
        () => Object.values(routesMap).reduce<SubRoute[]>((acc, route) => [...acc, ...(route.subRoutes ?? [])], []),

        [JSON.stringify(removeIconFromRoutes(routesMap))],
    );

    const currentRouteData = useMemo<Route | SubRoute | undefined>(() => {
        const mainRoute = routes.find((route) => route.id === selected);
        if (mainRoute) return mainRoute;
        return subRoutes.find((sub) => sub.id === selected);
    }, [routes, subRoutes, selected]);

    const topRoutes = [routesMap.home, routesMap.affiliates, routesMap.links, routesMap.settings];

    const bottomRoutes = [routesMap.faq, routesMap.contact, routesMap.demo];

    const isSidebarFloatingState = (): boolean => {
        return !!currentRouteData?.id && currentRouteData.sidebarStyle === "floating";
    };

    useEffect(() => {
        const pathMatch = pathname.match(/(\/[a-z-]*)\/?.*/);
        if (pathMatch !== null) {
            const path = pathMatch[1];
            const routeKey = path.replace("/", "");
            const newRouteSelected = findRoute({
                routeKey,
                routes,
                subRoutes,
            });
            if (newRouteSelected !== selected) {
                setSelected(newRouteSelected);
            }
        }
    }, [pathname, selected, currentLanguage]);

    const entries = useMemo(
        () => [
            {
                id: "top_routes",
                entries: topRoutes.map((route) => getRouteDefinition(navigate, location, visible, currentRouteData, route, currentLanguage)),
            },
            {
                id: "intercom_feedback",
                element: <CustomizableButton size={visible ? "large" : "small"} />,
            },
            {
                id: "connect_waalaxy",
                element: <AskWaalaxyAccount size={visible ? "large" : "small"} />,
            },
            {
                id: "bottom_routes",
                entries: bottomRoutes.map((route) => getRouteDefinition(navigate, location, visible, currentRouteData, route, currentLanguage)),
            },
        ],

        [visible, currentRouteData?.id, currentLanguage, hasRequirements],
    );

    const footers = useMemo(
        () => [
            {
                id: "translations",
                element: (
                    <StyledSelectLanguageDiv>
                        <LanguageSelect size={visible ? "large" : "small"} />
                    </StyledSelectLanguageDiv>
                ),
            },
        ],

        [visible, currentRouteData?.id, currentLanguage, hasRequirements],
    );

    const secondaryMenu = useMemo(() => {
        return secondaryMenuMap[selected];
    }, [selected]);

    const goHome = () => {
        navigate(routesMap.home.path);
    };

    return (
        <StyledSidebar
            onEnteredChange={setVisible}
            brand={{ title: <Title src={title} />, logo }}
            onBrandClick={goHome}
            open={isSidebarFloatingState() ? undefined : true}
            floating={isSidebarFloatingState()}
            backgroundColor="rgb(235 239 253)"
            selected={getRouteIdWithLanguage(selected, currentLanguage)}
            sections={entries}
            secondaryMenu={secondaryMenu?.element}
            extraMenuWidth={secondaryMenu ? secondaryMenu.menuWidth : "0px"}
            footers={footers}
        />
    );
}
