import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMixpanel } from "react-mixpanel-browser";
import { getMixpanelAuthenticated } from "@/customHooks/useMixpanel/selectors.js";
import { getUser } from "@/entities/User/hooks/useUserState/selectors.js";
import { useInjectReducer } from "redux-injectors";
import { actions, reducer, sliceKey } from "@/customHooks/useMixpanel/slice.js";
import { TrackErrorParams, TrackEventParams, UseMixpanelReturnType } from "@/customHooks/useMixpanel/types.js";

export const useInitMixpanel = () => {
    useInjectReducer({ key: sliceKey, reducer });
    const mixpanel = useMixpanel();
    const user = useSelector(getUser)!;
    const mixpanelAuthenticated = useSelector(getMixpanelAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        if (mixpanel?.config?.token && user?._id && !mixpanelAuthenticated) {
            mixpanel.identify(user?._id);
            dispatch(actions.setAuthenticated());
        }
    }, [user?._id, mixpanelAuthenticated]);
};

export const useMixpanelTrack = (): UseMixpanelReturnType => {
    useInjectReducer({ key: sliceKey, reducer });

    const mixpanel = useMixpanel();
    const mixpanelAuthenticated = useSelector(getMixpanelAuthenticated);

    const trackEvent = (params: TrackEventParams) => {
        const { eventName, properties } = params;
        if (mixpanel?.config?.token && mixpanelAuthenticated) {
            mixpanel.track(eventName, properties);
        }
    };

    const trackError = (params: TrackErrorParams) => {
        trackEvent({ eventName: "encounterAnError", properties: params });
    };

    return { trackEvent, trackError };
};
