import { Breakpoint } from "@waapi/waap-ui";

export const MAX_WIDTH_MAP: Record<Breakpoint, string> = {
    [Breakpoint.XXL]: "70%",
    [Breakpoint.XL]: "70%",
    [Breakpoint.LG]: "90%",
    [Breakpoint.MD]: "100%",
    [Breakpoint.SM]: "100%",
    [Breakpoint.XS]: "100%",
};
