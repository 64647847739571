import { Badge, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { getHasRequirements } from "@/entities/User/hooks/useUserState/selectors.js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getSettingsSecondayMenuItems } from "./constants.js";
import { isSettingsSecondaryMenuPage } from "./helpers/isSettingsSecondaryMenuPage.js";
import { MenuItem } from "./MenuItem/index.js";
import { Container, MenuItems } from "./style.js";
import { SettingsSecondaryMenuPage } from "./types.js";

export const SettingsSecondaryMenu = (): JSX.Element => {
    const [selected, setSelected] = useState<SettingsSecondaryMenuPage>(SettingsSecondaryMenuPage.MY_ACCOUNT);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t, translations } = useTranslation();

    const hasRequirements = useSelector(getHasRequirements);

    const changeRoute = (route: string) => navigate(`/settings/${route}`);

    useEffect(() => {
        const contentsPathMatch = pathname.match(/\/settings\/(.*)/);

        if (contentsPathMatch && contentsPathMatch.length === 2 && isSettingsSecondaryMenuPage(contentsPathMatch[1])) {
            setSelected(contentsPathMatch[1]);
        }
        // eslint-disable-next-line
    }, [pathname]);

    const settingsSecondayMenuItems = getSettingsSecondayMenuItems({ t, translations, hasRequirements });

    return (
        <Container>
            <Text size={TextSize.LARGE} weight={TextWeight.BOLD}>
                {t(translations.sidebar.route.settings())}
            </Text>

            <MenuItems>
                {Object.keys(settingsSecondayMenuItems).map((page) => {
                    const pageProperties = settingsSecondayMenuItems[page];
                    const component = <MenuItem {...pageProperties} value={page} onClick={() => changeRoute(page)} selected={selected === page} />;

                    return pageProperties.hasNotification ? (
                        <Badge color="error" variant="dark" yOffset="-0.5rem">
                            {component}
                        </Badge>
                    ) : (
                        component
                    );
                })}
            </MenuItems>
        </Container>
    );
};
