import useRequest from "@/customHooks/useRequest.js";
import useToasts from "@/customHooks/useToasts.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { actions } from "@/entities/User/hooks/useUserState/slice.js";
import { useDispatch } from "react-redux";
import { LinkAccountBody } from "@waapi/palpatine-client";

export const useAccountManager = () => {
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { t, translations } = useTranslation();
    const getOnboardingUrl = useRequest<"getOnboardingUrl">("getOnboardingUrl");
    const getUpdateUrl = useRequest<"getUpdateUrl">("getUpdateUrl");
    const getAccountLoginUrl = useRequest<"getAccountLoginUrl">("getAccountLoginUrl");
    const linkAccount = useRequest<"linkAccount">("linkAccount");
    const createAffiliation = useRequest<"createAffiliation">("createAffiliation");

    const handleGetOnboardingUrl = async (): Promise<string | undefined> => {
        const res = await getOnboardingUrl();
        if (res.hasFailed) return undefined;

        return res.data.url;
    };

    const handleGetUpdateUrl = async (): Promise<string | undefined> => {
        const res = await getUpdateUrl();
        if (res.hasFailed) return undefined;

        return res.data.url;
    };

    const handleGetAccountLoginUrl = async (): Promise<string | undefined> => {
        const res = await getAccountLoginUrl();
        if (res.hasFailed) return undefined;

        return res.data.url;
    };

    const handleLinkWaalaxy = async (params: LinkAccountBody) => {
        const res = await linkAccount({
            body: params,
        });
        if (res.hasFailed) {
            addToast(t(translations.waalaxy.toasts.failed()), { autoDismiss: true, appearance: "error" });
            return;
        }
        const { waalaxyId, profilePicture, firstName, lastName } = params;
        addToast(t(translations.waalaxy.toasts.success()), { autoDismiss: true, appearance: "success" });
        dispatch(
            actions.setWaalaxyAccount({
                waalaxyId,
                firstName,
                lastName,
                profilePicture,
            }),
        );
    };

    const handleChooseAdvantage = async ({
        params,
        onAdvantageChosen,
    }: {
        params: Parameters<typeof createAffiliation>;
        onAdvantageChosen: () => void;
    }) => {
        const res = await createAffiliation(...params);
        if (res.hasFailed) {
            addToast(t(translations.pages.choose_advantage.toasts.error_choose_advantage()), { autoDismiss: true, appearance: "error" });
            return;
        }

        onAdvantageChosen();
    };

    return {
        getOnboardingUrl: handleGetOnboardingUrl,
        getUpdateUrl: handleGetUpdateUrl,
        linkAccount: handleLinkWaalaxy,
        chooseAdvantage: handleChooseAdvantage,
        getAccountLoginUrl: handleGetAccountLoginUrl,
    };
};
