import { UseAffiliatesManagerReturnType } from "@/entities/Afffiliate/hooks/useAffiliatesManager/types.js";
import useRequest from "@/customHooks/useRequest.js";
import useToasts from "@/customHooks/useToasts.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";

export const useAffiliatesManager = (): UseAffiliatesManagerReturnType => {
    const exportAffiliates = useRequest<"exportAffiliates">("exportAffiliates");
    const getAffiliatesLinkedinIds = useRequest<"getAffiliatesLinkedinIds">("getAffiliatesLinkedinIds");

    const { addToast, addErrorToast } = useToasts();
    const { t, translations } = useTranslation();

    const handleExportAffiliates: UseAffiliatesManagerReturnType["exportAffiliates"] = async (params) => {
        const response = await exportAffiliates({
            body: params,
        });
        if (response.hasFailed) {
            addErrorToast(t(translations.toast.affiliates.error_export_csv()), response.error?.code);
            return false;
        }

        addToast(t(translations.toast.affiliates.success_export_csv()), { appearance: "success", autoDismiss: true });
        return true;
    };

    const handleGetAffiliatesLinkedinIds: UseAffiliatesManagerReturnType["getAffiliatesLinkedinIds"] = async (params) => {
        const response = await getAffiliatesLinkedinIds({
            body: params,
        });
        if (response.hasFailed) {
            addErrorToast(t(translations.toast.affiliates.error_get_affiliates_linkedin_ids()), response.error?.code);
            return false;
        }

        return response.data.linkedinIds;
    };

    return {
        exportAffiliates: handleExportAffiliates,
        getAffiliatesLinkedinIds: handleGetAffiliatesLinkedinIds,
    };
};
