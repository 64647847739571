import { AuthPage } from "@/app/components/AuthPage/index.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { SignupForm } from "./SignupForm/index.js";

export const Signup = (): JSX.Element => {
    const { t, translations } = useTranslation();

    return (
        <AuthPage
            title={translations.pages.signup.title()}
            subtitle={{ text: translations.pages.signup.subtitle(), link: { children: t(translations.pages.signup.subtitle_link()), to: "/login" } }}
            form={<SignupForm />}
        />
    );
};
